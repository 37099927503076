var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"formularioCliente",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"list-view product-checkout",on:{"submit":function($event){$event.preventDefault();return _vm.sendDataCliente($event)}}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"flex-column align-items-start"},[_c('b-card-title',[_vm._v("Your information")]),_c('b-card-text',{staticClass:"text-muted mt-25"},[_vm._v(" Make sure to fill in the required fields* ")])],1),_c('b-card-body',[_c('b-row',[(!_vm.queryParamsHasHotelAndCustomerHasHotel)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"hotels","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Choose a hotel*"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : (valid ? true : null)},on:{"change":function($event){return _vm.handleHotelClient(_vm.cart.cliente.hotel)}},model:{value:(_vm.cart.cliente.hotel),callback:function ($$v) {_vm.$set(_vm.cart.cliente, "hotel", $$v)},expression:"cart.cliente.hotel"}},[_c('option',{attrs:{"value":""}},[_vm._v("Choose hotel*")]),_vm._l((_vm.hotels),function(hotel){return _c('option',{key:hotel.id,domProps:{"value":hotel.id}},[_vm._v(_vm._s(hotel.name))])})],2),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Email*"}},[_c('b-form-input',{attrs:{"id":"Email-del-cliente","type":"email","state":errors[0] ? false : (valid ? true : null),"placeholder":"Enter email"},on:{"input":function($event){return _vm.checkIfEmailClientExist(_vm.cart.cliente.email)}},model:{value:(_vm.cart.cliente.email),callback:function ($$v) {_vm.$set(_vm.cart.cliente, "email", $$v)},expression:"cart.cliente.email"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Name*"}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : (valid ? true : null),"placeholder":"Enter name"},on:{"blur":function($event){return _vm.setNameClient(_vm.cart.cliente.name)}},model:{value:(_vm.cart.cliente.name),callback:function ($$v) {_vm.$set(_vm.cart.cliente, "name", $$v)},expression:"cart.cliente.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"surname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Surname*"}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : (valid ? true : null),"placeholder":"Enter surname"},on:{"blur":function($event){return _vm.setLastNameClient(_vm.cart.cliente.lastname)}},model:{value:(_vm.cart.cliente.lastname),callback:function ($$v) {_vm.$set(_vm.cart.cliente, "lastname", $$v)},expression:"cart.cliente.lastname"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|min:10","name":"phone","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Phone*"}},[_c('b-form-input',{attrs:{"oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxlength":"20","type":"text","state":errors[0] ? false : (valid ? true : null),"placeholder":"Enter phone"},on:{"blur":function($event){return _vm.setPhoneCliente(_vm.cart.cliente.phone)},"keypress":_vm.formatPhoneNumber},model:{value:(_vm.cart.cliente.phone),callback:function ($$v) {_vm.$set(_vm.cart.cliente, "phone", $$v)},expression:"cart.cliente.phone"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"float-right"},[(_vm.orderCreated)?_c('b-button',{staticClass:"mr-1",attrs:{"disabled":invalid || _vm.cart.items == 0,"variant":"danger"},on:{"click":_vm.cancelarOrden}},[_c('b-icon',{attrs:{"icon":"x-circle-fill"}}),_vm._v(" Cancel ")],1):_vm._e(),(_vm.orderCreated)?_c('b-button',{attrs:{"disabled":invalid || _vm.cart.items == 0,"variant":"primary","type":"submit"}},[_c('b-icon',{attrs:{"icon":"cash"}}),_vm._v(" Retry payment ")],1):_c('b-button',{attrs:{"disabled":invalid || _vm.cart.items == 0 || _vm.atributosBtnPay.disabledBtn,"variant":"primary","type":"submit"}},[(!_vm.isLoadingProcess)?_c('b-icon',{attrs:{"icon":"cash"}}):_vm._e(),(_vm.isLoadingProcess)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.atributosBtnPay.textBtn)+" ")],1)],1)])],1)],1)],1),_c('CustomerCard')],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<div class="customer-card">
		<b-card >
			<h4>{{cart.cliente.name+' '+cart.cliente.lastname}}</h4>
			<table class="table table-sm table-striped">
				<thead class="thead-white">
					<tr>
					<th scope="col">Email</th>                                                               
					<th scope="col">Phone</th>                                                  
					<th scope="col">Hotel</th>                                                  
					</tr>
				</thead>
				<tbody>                                    
					<tr>                                                                                           
					<td scope="row">{{cart.cliente.email}} </td> 
					<td scope="row">{{cart.cliente.phone}}</td>                                             
					<td scope="row">{{nameHotel}}</td>                                             
					</tr>   
				</tbody>
			</table>
			

			<div v-if="itemInCart" class=" item-detail mt-2"> 
			<hr>

				<div class="head-item">
					<p> <b>Name:</b> {{ itemInCart.name || itemInCart.experienceContractName}} </p>
					<p> <b>Date:</b> {{ itemInCart.operationdate }} </p>
				</div>
				<p><b>Notes:</b> {{ itemInCart.clientnote }}</p>
			<hr>

			</div> 

			<h4>Purchase breakdown</h4>
			<table class="table table-sm table-striped">
				<thead class="thead-white">
					<tr>
					<th scope="col">TC</th>                                                               
					<th scope="col">Total Currency</th>                                                  
					<th scope="col">Subtotal</th>     
					<th scope="col">Discount</th>     
					<th scope="col">Total</th>                                           
					</tr>
				</thead>
				<tbody>                                    
					<tr>                                                                                           
					<td scope="row">{{tipoCambio}}</td> 
					<td scope="row">{{totalDivisa}}</td>                                             
					<td scope="row">{{subTotal}}</td>                                             
					<td scope="row">{{totalDiscountOnAllProducts}}</td>                                             
					<td scope="row">{{total}}</td>  
					</tr>   
				</tbody>
			</table>
			
			<div v-if="notReadyItems.length"  class="mt-2">
			<hr>
			<b>Products in the cart with missing fields: </b><hr>
			<table class="table table-sm table-striped">
				<thead class="thead-dark">
					<tr>
					<th scope="col">Item</th>                                                               
					<th scope="col">Missing field or action</th>                                                  
					</tr>
				</thead>
				<tbody>                                    
					<tr v-for="(item, indice) in notReadyItems" :key="item.key">                                                                                           
					<td scope="row"><b>{{indice + 1}}:</b>  {{ item.name }} </td> 
					<td scope="row" style="color: red;">              
						<span v-for="(valor, index) in item.fields"
							:key="valor.key"
						> {{ valor.campo}} <span  v-if="index != item.fields.length - 1">,</span>
						</span>  
					</td>                                             
					</tr>   
				</tbody>
			</table>                               
			</div>     
		</b-card>
  	</div>    
</template>

<script>

import { mapState, mapGetters } from 'vuex'
import { itemsCartNotReady }from '@/helpers/shopHelper'
import { toDecimal }from '@/helpers/helpers'


export default { 
	computed:{ 
		...mapState('shop',['cart']),
		...mapState('start',['customerTypes','hotels','paymentMethods','languages']),
		...mapGetters('shop',['totalDiscountOnAllProducts']),    
		notReadyItems(){
			return itemsCartNotReady( this.cart.items )
		},
		itemInCart(){
			return this.cart.items.length > 0 ? this.cart.items[0] : null
		} ,
		nameHotel(){
			let hotel
			if( this.cart.cliente.hotel != ''){
				hotel = this.hotels.find( h => h.id === this.cart.cliente.hotel )
			}
			return hotel ? hotel.name : ''                        
		},
		tipoCambio() {
			return toDecimal(this.cart.cliente?.currency?.value) || 1
		},
		totalDivisa() {
			return ( Math.round( parseFloat(this.cart.totalBreakdown.total) * parseFloat(this.cart.cliente?.currency?.value) * 100 ) / 100 ).toFixed(2)
		},
		subTotal() {
			return ( Math.round( (parseFloat(this.totalDiscountOnAllProducts) + parseFloat(this.cart.totalBreakdown.total)) * 100 ) / 100 ).toFixed(2)
		},
		total() {
			return toDecimal(this.cart.totalBreakdown.total) 
		},
  },  
 
}
</script>

<style lang="scss" scoped>
.item-detail p{
	margin: 0;
}
.head-item{

	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0;
}
</style>
<template>
    <div>        
        <ValidationObserver ref="formularioCliente" tag="form" v-slot="{ invalid }">            
            <b-form class="list-view product-checkout" @submit.prevent="sendDataCliente">      
                <b-card no-body>
                    <b-card-header class="flex-column align-items-start">
                        <b-card-title>Your information</b-card-title>
                        <b-card-text class="text-muted mt-25">
                            Make sure to fill in the required fields*                        
                        </b-card-text>
                    </b-card-header>
                    <b-card-body>
                        <b-row>            
                            <b-col cols="12" md="6" v-if="!queryParamsHasHotelAndCustomerHasHotel">
                                <ValidationProvider name="hotels" rules="required">
                                    <b-form-group slot-scope="{ valid, errors }" label="Choose a hotel*">
                                    <b-form-select                                         
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            @change="handleHotelClient(cart.cliente.hotel)"
                                            v-model="cart.cliente.hotel"
                                        >
                                        <option value="">Choose hotel*</option>                                            
                                        <option v-for="hotel in hotels" :key="hotel.id" :value="hotel.id">{{hotel.name}}</option>
                                    </b-form-select>
                                        <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>                     
                            <b-col cols="12" md="6">
                                <ValidationProvider rules="required|email" name="email">
                                    <b-form-group slot-scope="{ valid, errors }" label="Email*" class="mb-2">
                                        <b-form-input
                                            id="Email-del-cliente"
                                            type="email"
                                            v-model="cart.cliente.email"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            placeholder="Enter email"
                                            @input="checkIfEmailClientExist(cart.cliente.email)"
                                        >
                                        </b-form-input>
                                        <b-form-invalid-feedback>
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            
                            <b-col cols="12" md="6">
                                <ValidationProvider rules="required" name="name">
                                    <b-form-group slot-scope="{ valid, errors }" label="Name*">
                                        <b-form-input
                                            type="text" v-model="cart.cliente.name"
                                            :state="errors[0] ? false : (valid ? true : null)"     
                                            placeholder="Enter name"
                                            @blur="setNameClient(cart.cliente.name)"
                                        >
                                        </b-form-input>
                                        <b-form-invalid-feedback>
                                            {{ errors[0] }} 
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>                                                
                            </b-col>
                            <b-col cols="12" md="6">
                                <ValidationProvider rules="required" name="surname">
                                    <b-form-group slot-scope="{ valid, errors }" label="Surname*"
                                    >
                                        <b-form-input
                                            type="text"
                                            v-model="cart.cliente.lastname"
                                            :state="errors[0] ? false : (valid ? true : null)"     
                                            placeholder="Enter surname"
                                            @blur="setLastNameClient(cart.cliente.lastname)"
                                        >
                                        </b-form-input>
                                        <b-form-invalid-feedback>
                                            {{ errors[0] }} 
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>                 
                            </b-col>

                            <b-col cols="12" md="6">
                                <ValidationProvider rules="required|min:10"  name="phone" vid="phone">
                                    <b-form-group slot-scope="{ valid, errors }" label="Phone*">      
                                        <b-form-input
                                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                            maxlength="20"
                                            type="text"
                                            v-model="cart.cliente.phone"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            placeholder="Enter phone"
                                            @blur="setPhoneCliente(cart.cliente.phone)"
                                            @keypress="formatPhoneNumber" 
                                        >
                                            <!-- @keypress="formatPhoneNumber"                                    -->
                                        </b-form-input>
                                        <b-form-invalid-feedback>
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>                     
                        </b-row>
                        <b-row>
                            
                            <b-col>
                                <div class="float-right">                                   
                                    <b-button
                                        v-if="orderCreated"
                                        :disabled="invalid || cart.items == 0"
                                        variant="danger"
                                        class="mr-1"
                                        @click="cancelarOrden"
                                    >
                                        <b-icon icon="x-circle-fill"></b-icon> Cancel
                                    </b-button>
                                    <b-button
                                        v-if="orderCreated"
                                        :disabled="invalid || cart.items == 0"
                                        variant="primary"
                                        type="submit"
                                    >
                                        <b-icon icon="cash"></b-icon> Retry payment
                                    </b-button>
                                    <b-button
                                        v-else
                                        :disabled="invalid || cart.items == 0 || atributosBtnPay.disabledBtn "
                                        variant="primary"
                                        type="submit"
                                    >
                                        <b-icon icon="cash" v-if="!isLoadingProcess"></b-icon>
                                        <b-spinner small v-if="isLoadingProcess"/> {{atributosBtnPay.textBtn}}  
                                    </b-button>                                
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-body>                
            </b-card>      

            <CustomerCard />
            
            </b-form>
        </ValidationObserver>
    </div>        
</template>

<script>
//eslint-disable-line
import { mapState, mapActions,mapMutations } from 'vuex'
import { validateDataInCart, checkItemsCartSihotCode } from '@/helpers/helpers'
import CustomerCard from '@/modules/shop/components/checkout/CustomerCard'
import { overWriteAxiosHeaders } from '@/helpers/helpers'
import { stepOneDL, stepTwoDL } from '@/helpers/dataLayerHelpers'

export default {
    async created() {
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
        if(this.cart.items.length > 0 ){
            if( this. cart.totalBreakdown.total == 0){
                this.setPaymentMethodClient(1035)
            } 
    
            if(this.cart.totalBreakdown.total > 0){
                if(this.user.isMultiProduct){
                    this.setPaymentMethodClient(1051) //credit card epic
                } else {
                    this.setPaymentMethodClient(1) //credit card
                }
            }     
        }
        if(!this.queryParams){
            this.$router.push({name: 'not-authorized' })
        } else {
            await overWriteAxiosHeaders( this.queryParams ) 
            await this.getInitialContentCheckout()                       
            if( this.$gtm.enabled() ){
                stepOneDL()// envio a DataLayer
            }
        }
        if( this.cart.cliente.customertype == '' ){
            this.setTipoCliente(8) // el tipo de cliente es Directo Web
        }

    },       
    destroyed() {
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    },
    components: {
        CustomerCard,
    },    
    computed:{
        ...mapState('auth',['queryParams', 'user']),                  
        ...mapState('shop',['cart','orderCreated','isLoadingProcess']),
        ...mapState('start',['hotels',]),            
        computedForm(){
            return this.$refs.form
        },        
        queryParamsHasHotelAndCustomerHasHotel(){
            const clientHasHotel = this.cart.cliente.hotel != '' ? true : false
            const queryParamsHasHotel = this.queryParams?.hotel ? true : false
            return clientHasHotel && queryParamsHasHotel
        },
        atributosBtnPay(){
            const textBtn = this.isLoadingProcess ? 'Processing Sale' : 'Finish'
            const disabledBtn = this.isLoadingProcess ? true : false
            return {textBtn, disabledBtn}
        },
    },
    methods: {        
        ...mapActions('shop',['checkIfClientExists','sendInfoClient','cancelOrder','getInitialContentCheckout']),        
        ...mapMutations('auth',['setUser']),        
        ...mapMutations('shop',['setHotelCliente', 'setLastNameClient', 'setNameClient','setPhoneCliente','setTipoCliente','setPaymentMethodClient']),           
        handleHotelClient( hotel ){   
            this.setHotelCliente(hotel)                                                                                                                   
        },        
        async checkIfEmailClientExist( email ){
            //eslint-disable-next-line
            const reg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            if( reg.test(email ) ){                    
                await this.checkIfClientExists(this.cart.cliente)
            }
        },
       async sendDataCliente(){
            //valida que ningún item tenga campos obligatorios sin llenar
            const existsItem = validateDataInCart( this.cart.items )
            checkItemsCartSihotCode( this.cart.items) 
            if( !existsItem ){
                if( this.$gtm.enabled() ){
                    await stepTwoDL()
                }              
                await this.sendInfoClient( this.cart.cliente )
                if( this.cart.items ){
                    this.resetCustomerForm()  //reseteo validacion veevalidate                                 
                }
            }                     
        },
        async cancelarOrden(){
            await this.cancelOrder( this.orderCreated )
            this.resetCustomerForm()  //reseteo validacion veevalidate         
        },
        resetCustomerForm(){            
            this.$refs.formularioCliente.reset()  //reseteo validacion veevalidates
        },
     
        formatPhoneNumber(event){      
            let keyCode = (event.keyCode ? event.keyCode : event.which)            
            //43 es +, 48-57(numeros del 0 al 9, 32 espacio, 45 es - )
            const codesAllowed = [ 32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
            const respuesta = codesAllowed.includes(keyCode)
			if (!respuesta) {                                       
				event.preventDefault()
			}
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
button.btn.btn-info[data-v-e920eb32] {
    background-color: #2f7d3f !important;
}
</style>


